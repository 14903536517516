/* Router */
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  margin:-15px 0 0 -15px;
  -webkit-animation:spin 2s linear infinite;
  -moz-animation:spin 2s linear infinite;
  animation:spin 2s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

/* SideBar */
.sidebar {
  background: #fff;}
  .sidebar .nav-link {
    display: block;
    padding: 0.75rem 1rem;
    color: #3a4248;
    text-decoration: none;
    background: transparent; }
    .sidebar .nav-link .nav-icon {
      display: inline-block;
      width: 1.09375rem;
      margin: 0 0.5rem 0 0;
      font-size: 0.875rem;
      color: #20a8d8;
      text-align: center; }
    .sidebar .nav-link .badge {
      float: right;
      margin-top: 2px; }
  .sidebar .nav-link.active {
    color: #3a4248;
    background: #fff;}
    .sidebar .nav-link.active .nav-icon {
      color: #20a8d8; }
    .sidebar .nav-link:hover {
      background: #ececec; }
    .sidebar .nav-item {
      height: 60px;}

/* table */
@media (max-width: 700px) {
  .table {
    overflow: auto;
    display: block;
    table-layout: auto;
  }
}